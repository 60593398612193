import React, { useEffect, useState, useRef } from "react"
import tw, {css} from "twin.macro"
import useScript from '../../hooks/useScript'

const Ad = () => {

	const [playerWidth, setPlayerWidth] = useState(640)
	const [playerHeight, setPlayerHeight] = useState(360)

 	useEffect(() => {
		
		if (adAreaRef.current) {

			const playerWidths = [1920,1280,854,640]
			const playerHeights = [1080,720,480,360]
			let i = 0
			
			while (playerWidths[i] > (adAreaRef.current.offsetWidth) && i < playerWidths.length) { i++ }

			setPlayerWidth(playerWidths[i])
			setPlayerHeight(playerHeights[i])

		}

	}, [])

	const cinewebTagStatus = useScript('https://tg1.aniview.com/api/adserver/spt?AV_TAGID=6368134ea82b2115182fdaae&AV_PUBLISHERID=636812a0a5f32a28d73fc197')

	const adAreaRef = useRef()

	return (<>
		<div tw="absolute w-full h-full hidden md:block bg-blue pt-14 pr-6" css={[
			{
				paddingLeft: "21rem"
			}
		]}>
			<div ref={adAreaRef} tw="h-full w-full bg-blue flex items-center justify-center">
				<div id="globalsun" tw="bg-red" style={{"width":`${playerWidth}px`, "height":`${playerHeight}px`}}>
				</div>
			</div>
		</div>
	</>)
}

export default Ad